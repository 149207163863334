<template>
  <div class="aboutUs-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header"></div>
      <div class="certificate">
        <Row type="flex" justify="start" class="code-row-bg">
          <Col
            span="6"
            class="certificateContainer"
            v-for="item in cooperationList"
            :key="item.id"
          >
            <img :src="item.partnerUrl" alt="" width="270px" height="260px" />
            <div class="cooperationName">{{ item.partnerName }}</div>
          </Col>
        </Row>

        <!-- <div
            class="certificateContainer"
            v-for="item in cooperationList"
            :key="item.id"
          >
            <div>
              <img :src="item.partnerUrl" alt="" class="cooperationImg" />
              <div class="cooperationName">{{ item.partnerName }}</div>
            </div>
          </div> -->
      </div>
      <div class="dataPage">
        <Page
          :total="totalNum"
          show-elevator
          show-total
          :page-size="pageSize"
          :current="currentPage"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>

    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>
  
  <script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getCooperationList } from "@/api/newWebsite";
export default {
  name: "school-cooperation",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚-合作案例 智慧通行|人脸识别终端|人行通道闸机|校园案例 园区案例 智慧公寓案例 人才公寓案例 保障性住房案例",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      cooperationList: [],
      totalNum: 0,
      pageSize: 16,
      currentPage: 1,
    };
  },
  methods: {
    changePage(index) {
      this.currentPage = index;
      this.getCooperationList();
    },
    changePageSize(index) {
      this.pageSize = index;
      this.getCooperationList();
    },

    getCooperationList() {
      getCooperationList({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        cooperationType: 2, //（1 合作客户，2 校园案例，3 公租房人才公寓案例）
      })
        .then((res) => {
          // console.log("合作伙伴列表", res);
          if (res.code == 0) {
            this.cooperationList = res.data.list;
            this.totalNum = res.data.pageTotal;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取合作伙伴失败");
        });
    },
  },
  created() {
    this.getCooperationList();
  },
  mounted() {},
};
</script>
  
  <style scoped>
.dataPage {
  margin-top: 25px;
  margin-bottom: 30px;
}
.dataPage >>> .ivu-page-item-active {
  border-color: #ea5413;
}
.dataPage >>> .ivu-page-item-active a {
  color: #ea5413;
}
.dataPage >>> .ivu-page-item:hover {
  border-color: #ea5413;
  color: #ea5413;
}
.dataPage >>> .ivu-page-item a:hover {
  color: #ea5413 !important;
}
.aboutUs-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/new/school.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.header-content {
  height: 160px;
  margin: auto;
  padding-top: 120px;
  padding-left: 20px;
}
.title {
  font-size: 40px;
  margin-top: 30px;
  font-weight: bold;
}
.contact_list {
  margin-top: 40px;
  width: 1200px;
  margin: auto;
}
.line {
  width: 80px;
  margin: auto;
  border-bottom: 2px solid #d34100;
  margin-top: 10px;
}
.content {
  font-size: 15px;
  padding-top: 20px;
  color: #333333;
  margin: auto;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
}
.viedo {
  width: 1200px;
  height: 520px;
  margin: auto;
  margin-top: 30px;
  /* background-image: url("../assets/images/about/mv.png"); */
}
.about_list {
  width: 1200px;
  height: 400px;
  margin: auto;
  margin-top: 50px;
}
.about_list p {
  font-size: 16px;
  line-height: 24px;
  color: #878a95;
  font-weight: 400;
}
.certificate {
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
  /* display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; */
}
.bg_about {
  background-image: url("../assets/images/about/introduce_bg.png");
  width: 100%;
  background-size: cover;
}
.list_content {
  background-image: url("../assets/images/about/introduce_bg2.png");
  /* background-size: cover; */
  background-size: contain;
  height: 295px;
}
.list_content img {
  margin-top: 20px;
}
.list_content p {
  color: #343434;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
}
.viedo >>> .vjs-poster {
  background-size: cover !important;
}
.viedo >>> .video-js:hover .vjs-big-play-button {
  background-color: #ea5413;
}
.viedo >>> .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 72px;
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 72px !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}
.certificateContainer {
  /* width: 30%; */
  /* padding: 10px; */
  /* margin-bottom: 20px; */
  /* box-shadow: 1px 1px 6.44px 2.56px rgb(232 235 234 / 39%); */
}
.cooperationName {
  position: relative;
  top: -40px;
  left: 35px;
  background-color: #fff;
  /* width: calc(100% - 40px); */
  width: 230px;
  padding: 25px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  /* -webkit-box-shadow: 0px 13px 35px -12px rgb(35 35 35 / 15%);
      -moz-box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15); */
  box-shadow: 0px 13px 35px -12px rgb(35 35 35 / 15%);
}
.cooperationName:hover {
  color: #cfa972;
}
.cooperationImg {
  width: 350px;
  height: 260px;
}
@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .certificateContainer {
    width: 80%;
    margin: auto;
    padding: 0;
  }
  .cooperationImg {
    width: 250px;
    height: 160px;
  }
  .cooperationName {
    width: 60%;
    height: 60px;
    line-height: 60px;
    padding: 0px;
    left: 20%;
  }
}
</style>